/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* mapbox css */
@import '~mapbox-gl/dist/mapbox-gl.css';

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Global scss for modal pop-up */
.invoice-modal-css {
  margin: auto;
  --background: transparant;
  top: 5%;
}

.next-order-modal-css {
  --background: transparant;
  top: 25%;
  margin: auto;
}

.input-code-modal-css {
  --background: transparant;
  top: 25%;
}

.vehicle-selection-css {
  --background: transparant;
  max-height: 60%;
  top: 5%;
}

.vehicle-selection-list-css {
  --max-height: 40%;
}

// account activation camera view transparency
ion-app.cameraView, ion-app.cameraView ion-content, ion-app.cameraView .nav-decor, ion-modal.cameraView {
  --background: transparent none !important;
  background-color: transparent;

}

ion-app {
  background-color: black;
}

// default styling
ion-button {
  text-transform: none;
  --border-radius: 10px;

  &.button-disabled {
    opacity: 1;
    color: var(--ion-color-secondary-contrast) !important;
    --background: var(--ion-color-secondary) !important;
    --box-shadow: none;
  }
}

ion-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
}